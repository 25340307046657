<template>
  <div class="open">
    <van-nav-bar
      :title="$t('Menu.openAccount')"
      left-arrow
      :border="false"
      @click-left="$router.push('/home_account')"
    />
    <van-cell-group class="open-group">
      <van-cell :title="$t('NewAccount.tradingAccount')" is-link @click="accountOnSelect" />
      <van-cell :title="$t('NewAccount.agentAccount')" is-link @click="accountOnSelect1" />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  methods: {
    accountOnSelect() {
      this.$router.push({
        path: "/member/newaccount",
        query: {
          id: 0,
        },
      });
    },
    accountOnSelect1() {
      this.$router.push({
        path: "/member/newaccount",
        query: {
          id: 1,
        },
      });
    },
  },
};
</script>

<style scoped>
.open {
  background-color: #f7f8fa;
}
.open-group {
  margin-top: 10px;
}
</style>